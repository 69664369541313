import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { isNullOrUndefined } from "util";

import { ResponseInterface } from "../../models/authorization/response-interface";

@Injectable({
    providedIn: "root"
})
export class ProjectService {
    private urlBase: string;
    private headers: HttpHeaders = new HttpHeaders({ "Content-Type": "application/json" });

    constructor(public _http: HttpClient) {
        //this.urlBase = 'http://localhost:3000/';
        this.urlBase = 'https://timesheet-api.e2api.com/projects/'; //Server
    }

    getProjectsByCompany(companyId: number): Observable<any> {
        return this._http.get<ResponseInterface>(this.urlBase + `${companyId}`).pipe(map(data => data));
    }

    getActiveProjectsByCompany(companyId: number, active: number): Observable<any> {
        return this._http.get<ResponseInterface>(this.urlBase + `${companyId}/${active}`).pipe(map(data => data));
    }

    saveProject(project: Object){
        return this._http.post<ResponseInterface>(this.urlBase + `save`, project, { headers: this.headers }).pipe(map(data => data));
    }
}