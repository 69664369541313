import { NgModule } from '@angular/core';

import { 
  MatButtonModule, 
  MatCheckboxModule, 
  MatSelectModule, 
  MatToolbarModule,
  MatIconModule,
  MatMenuModule,
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatGridListModule,
  MatSidenavModule,
  MatListModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatTableModule,
  MatSnackBarModule
} from '@angular/material';


@NgModule({
  exports: [
    MatButtonModule, 
    MatCheckboxModule, 
    MatToolbarModule, 
    MatIconModule, 
    MatMenuModule, 
    MatCardModule, 
    MatFormFieldModule, 
    MatInputModule,
    MatGridListModule,
    MatSidenavModule,
    MatListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatTableModule,
    MatSnackBarModule
  ]
})
export class AppMaterialModule { }