import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

import { EventService } from '../../services/event/event.service';
import { CompanyService } from '../../services/company/company.service';
import { ProjectService } from '../../services/project/project.service';
import { TimeRecordService } from '../../services/timerecord/timerecord.service';

import { AuthService } from "../../services/authorization/auth.service";

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'workspace',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.css']
})
export class WorkspaceComponent implements OnInit {

  public events: Object[];
  public companies: Object[];
  public projects: Object[];
  public details: Object[];

  public timeRecord: any;
  public detailTimeRecord: any;
  private employeeId: number;
  public companyId: number;

  selectedRowIndex: number = -1;

  public date: Date;
  public notes: String;

  public isError = false;

  public displayedColumns: string[] = ['eventName', 'projectName', 'activity', 'hours'];

  constructor(
    private eventService: EventService,
    private companyService: CompanyService,
    private projectService: ProjectService,
    private timeRecordService: TimeRecordService,
    private authService: AuthService,
    public datepipe: DatePipe,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.initComponents();
  }

  private initComponents() {
    this.initTimeRecord();
    this.initDetailTimeRecord();
    this.loadEvents();
    this.loadCompanies();
    this.employeeId = this.authService.getCurrentUser()['employeeId'];
  }

  private initDetailTimeRecord(): void {
    this.detailTimeRecord = null;
    this.companyId = undefined;
    this.projects = null;
    this.detailTimeRecord = {
      'detailTimeRecordId': null,
      'eventId': null,
      'projectId': null,
      'activity': null,
      'hours': null
    }
  }

  private initTimeRecord(): void {
    this.timeRecord = null;
    this.timeRecord = {
      'timeRecordId': null,
      'date': null,
      'employeeId': null,
      'notes': null
    };
  }

  loadEvents(): void {
    this.eventService.getActiveEvents(1).subscribe(data => { this.events = data.response.objects; }, error => this.onIsError());
  }

  loadCompanies(): void {
    this.companyService.getAllCompanies().subscribe(data => { this.companies = data.response.objects; }, error => this.onIsError());
  }

  loadProjectsByCompany(companyId: number): void {
    this.projectService.getActiveProjectsByCompany(companyId, 1).subscribe(data => { this.projects = data.response.objects; }, error => this.onIsError());
  }

  eventChangeDateSelect(date): void {
    let latest_date = this.datepipe.transform(date, 'yyyy-MM-dd');
    this.timeRecordService.getTimeRecord(this.employeeId, latest_date)
      .subscribe(
        data => {
          this.timeRecord = data.response.objects[0];
          this.details = null;
          if (this.timeRecord != null) {
            this.loadDetails();
            this.notes = this.timeRecord.notes;
          } else {
            this.notes = null;
            this.initTimeRecord();
          }
        },
        error => this.onIsError()
      );
  }

  loadDetails(): void {
    this.timeRecordService.getTimeRecordDetails(this.timeRecord.timeRecordId).subscribe(data => { this.details = data.response.objects; }, error => this.onIsError());
  }

  onSave(): void {
    this.timeRecord.date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
    this.timeRecord.notes = this.notes;

    let object = {
      'pTimeRecordId': this.timeRecord.timeRecordId,
      'pDate': this.timeRecord.date,
      'pEmployeeId': this.employeeId,
      'pNotes': this.timeRecord.notes,
      'pDetailTimeRecordId': this.detailTimeRecord.detailTimeRecordId,
      'pEventId': this.detailTimeRecord.eventId,
      'pProjectId': this.detailTimeRecord.projectId,
      'pActivity': this.detailTimeRecord.activity,
      'pHours': this.detailTimeRecord.hours
    };

    this.timeRecordService.saveTimeRecord(object)
      .subscribe(
        data => {
          let obj = data.response;
          if (obj.success) {
            this.timeRecord = {
              'timeRecordId': obj.objects[0].timeRecordId,
              'notes': obj.objects[0].notes,
              'date': obj.objects[0].date
            };
            this.initDetailTimeRecord();
            this.loadDetails();
          } else {
            this.initTimeRecord();
            this.initDetailTimeRecord();
          }
        }, error => this.onIsError());
    this.selectedRowIndex = -1;
  }

  onCancel(): void {
    this.initDetailTimeRecord();
    this.initTimeRecord();
    this.date = null;
    this.notes = null;
    this.details = null;
    this.selectedRowIndex = -1;
  }

  selectRow(row): void {
    this.selectedRowIndex = row.detailTimeRecordId;
    this.initDetailTimeRecord();
    this.detailTimeRecord = {
      'detailTimeRecordId': row.detailTimeRecordId,
      'eventId': row.eventId,
      'projectId': row.projectId,
      'activity': row.activity,
      'hours': row.hours
    }
    this.companyId = row.companyId;
    this.loadProjectsByCompany(this.companyId);
  }

  onIsError(): void {
    this.isError = true;
    setTimeout(() => { this.isError = false; }, 4000);
  }

}
