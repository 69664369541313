import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { isNullOrUndefined } from "util";

import { ResponseInterface } from "../../models/authorization/response-interface";

@Injectable({
    providedIn: "root"
})
export class EventService {
    private urlBase: string;
    private headers: HttpHeaders = new HttpHeaders({ "Content-Type": "application/json" });

    events: Observable<any>;

    constructor(public _http: HttpClient) {
        //this.urlBase = 'http://localhost:3000/events/';
        this.urlBase = 'https://timesheet-api.e2api.com/events/'; //Server
    }

    getAllEvents(): Observable<any> {
        return this._http.get<ResponseInterface>(this.urlBase).pipe(map(data => data));
    }

    getActiveEvents(active: number): Observable<any> {
        return this._http.get<ResponseInterface>(this.urlBase + `${active}`).pipe(map(data => data));
    }

    saveEvent(event: Object){
        return this._http.post<ResponseInterface>(this.urlBase + `save`, event, { headers: this.headers }).pipe(map(data => data));
    }
}