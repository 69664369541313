import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { AuthService } from '../app/services/authorization/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
    public authService: AuthService, 
    private router: Router) { }

  ngOnInit() {
  }

  onLogout(): void {
    this.authService.logout();
    this.router.navigate(['/']);
  }
}
