import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { isNullOrUndefined } from "util";

import { ResponseInterface } from "../../models/authorization/response-interface";

@Injectable({
    providedIn: "root"
})
export class TimeRecordService {
    private urlBase: string;
    private headers: HttpHeaders = new HttpHeaders({ "Content-Type": "application/json" });

    constructor(public _http: HttpClient) {
        //this.urlBase = 'http://localhost:3000/';
        this.urlBase = 'https://timesheet-api.e2api.com/'; //Server
    }

    getTimeRecord(employeeId: number, date: string): Observable<any> {
        return this._http.get<ResponseInterface>(this.urlBase + `timerecord/${employeeId}/${date}`).pipe(map(data => data));
    }

    getTimeRecordDetails(timeRecordId: number): Observable<any> {
        return this._http.get<ResponseInterface>(this.urlBase + `detailstimerecord/${timeRecordId}`).pipe(map(data => data));
    }

    saveTimeRecord(timeRecord: Object): Observable<any> {
        return this._http.post<ResponseInterface>(this.urlBase + `timerecord/save`, timeRecord, { headers: this.headers }).pipe(map(data => data));
    }
}