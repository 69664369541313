import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { isNullOrUndefined } from "util";

import { ResponseInterface } from "../../models/authorization/response-interface";

@Injectable({
    providedIn: "root"
})
export class AuthService {
    private urlBase: string;
    private headers: HttpHeaders = new HttpHeaders({ "Content-Type": "application/json" });

    constructor(public _http: HttpClient) {
        //this.urlBase = 'http://localhost:3000/';
        this.urlBase = 'https://timesheet-api.e2api.com/'; //Server
    }

    login(userName: string, password: string): Observable<any> {
        return this._http.get<ResponseInterface>(this.urlBase + `users/${userName}/${password}`).pipe(map(data => data));
    }

    setUser(user: Object): void {
        let user_string = JSON.stringify(user);
        localStorage.setItem("currentUser", user_string);
    }

    getCurrentUser(): Object {
        let user_string = localStorage.getItem("currentUser");
        if (!isNullOrUndefined(user_string)) {
            return JSON.parse(user_string);
        } else {
            return null;
        }
    }

    isLoggedIn(): boolean {
        return this.getCurrentUser() != null;
    }

    logout(): boolean {
        localStorage.removeItem("currentUser");
        return true;
    }
}