import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material';

import { AuthService } from '../../services/authorization/auth.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public user: any;

  constructor(private authService: AuthService, private router: Router, private location: Location, private snackBar: MatSnackBar) {
    this.user = {
      'userName': '',
      'password': ''
    };
  }

  public isError = false;

  ngOnInit() {
  }

  onLogin(): void {
    this.authService.login(this.user.userName, this.user.password)
      .subscribe(
        data => {
          let object = data.response;
          this.isError = !object.success;
          if (object.success) {
            let user = object.objects[0];
            if (user != null) {
              this.authService.setUser(user);
              this.router.navigate(['/workspace']);
            } else {
              this.user.password = '';
              this.snackBar.open('Incorrect Username/Password!!!', null, { duration: 2000 });
            }
          } else {
            this.snackBar.open('An error has occurred!!', null, { duration: 2000 });
          }
        },
        error => this.onIsError()
      );
  }

  onIsError(): void {
    this.isError = true;
    setTimeout(() => {
      this.isError = false;
    }, 4000);
  }

}
